var searchKeys = [{
  key: 'dates',
  label: '时间',
  format: "YYYY-MM-DD HH:mm",
  placeholder: ["开始日期", "截止日期"],
  dates: true,
  rules: [{
    type: 'array',
    required: false,
    message: '请选择时间段!'
  }]
},
// {
//     key: 'type',
//     label: '类型',
//     placeholder: "请输入",
//     required: false,
//     rules: [],
//     select: true
// },
{
  key: "button",
  label: "",
  placeholder: "",
  button: true
}];
export { searchKeys };