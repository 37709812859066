var columns = [{
  title: "IOS1",
  dataIndex: "iosimgurl",
  key: "iosimgurl",
  width: "10%",
  scopedSlots: {
    customRender: "iosimgurl"
  }
}, {
  title: "IOS2",
  dataIndex: "ios2imgurl",
  key: "ios2imgurl",
  width: "10%",
  scopedSlots: {
    customRender: "ios2imgurl"
  }
}, {
  title: "Android",
  dataIndex: "androidimgurl",
  key: "androidimgurl",
  width: "10%",
  scopedSlots: {
    customRender: "androidimgurl"
  }
}, {
  title: "视频",
  dataIndex: "videourl",
  key: "videourl",
  width: "10%",
  scopedSlots: {
    customRender: "videourl"
  }
}, {
  title: "开始时间—结束时间",
  dataIndex: "begintime",
  key: "begintime",
  width: "35%",
  scopedSlots: {
    customRender: "begintime"
  }
}, {
  title: "内容",
  dataIndex: "content",
  key: "content",
  width: "15%",
  scopedSlots: {
    customRender: "content"
  }
}, {
  title: "是否允许跳过",
  dataIndex: "isskip",
  key: "isskip",
  width: "10%",
  scopedSlots: {
    customRender: "isskip"
  }
}, {
  title: "倒计时(s)",
  dataIndex: "countdown",
  key: "countdown",
  width: 100,
  scopedSlots: {
    customRender: "countdown"
  }
}, {
  title: "操作",
  key: "splashaction",
  dataIndex: "splashaction",
  fixed: "right",
  width: 200,
  scopedSlots: {
    customRender: "splashaction"
  }
}];
export { columns };